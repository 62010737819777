<script>
import {getColorByStatus} from "@/helpers/getColorByStatus";

export default {
  name: "InfoModal",
  props: {
    value: {type: Boolean},
    title: {type: String},
    status: {type: String},
    date:  {type: String},
    address: {type: String},
  },
  methods: {
    getBgByStatus: getColorByStatus,
    changeState(value) {
      this.$emit("update:value", value)
    }
  }
}
</script>

<template>
  <b-modal :visible="value" @change="changeState" centered>
    <template #modal-header>
      {{ title }}
    </template>
    <div class="text-center">
      <p >Status: <span :class="getBgByStatus(status)">{{status}}</span> </p>
      <p>Address: {{address}}</p>
      <p class="m-0">Last connection: {{date}}</p>
    </div>
    <template #modal-footer="{ ok }">
      <b-button block size="sm" variant="success" @click="ok()">
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<style scoped lang="scss">

</style>