<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";
//
import "echarts/lib/chart/pie";
import {pieChart} from "@/router/views/charts/echart/data";
import "echarts/lib/component/polar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/scatter";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/toolbox";
import "echarts/lib/chart/bar";
import ECharts from "vue-echarts";
import DatePicker from "vue2-datepicker";
import InfoModal from "@/components/modals/InfoModal.vue";
import {tableData} from "./tables/data-advancedtable"

export default {
  page: {
    title: "Dashboard",
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {
    "v-chart": ECharts,
    Layout,
    DatePicker,
    InfoModal
  },
  data() {
    return {
      pieChart: pieChart,
      tableData: tableData,
      dateRange: "",
      isOpenedModal: false,
      coffeeMachines: ["Coffee machine 1", "Coffee machine 2", "Coffee machine 3", "Coffee machine 4"],
      coffeeMachineFilter: 'Coffee machine 1',
      selectedCoffeeMachine: null,
    };
  },
  methods: {
    clickHandler(clickedId) {
      this.isOpenedModal = !this.isOpenedModal
      if (this.isOpenedModal) {
        this.selectedCoffeeMachine = this.tableData.find(({id}) => id === clickedId)
      }
    }
  }
};
</script>

<template>
  <Layout>
    <InfoModal v-model="isOpenedModal"
               :title="selectedCoffeeMachine?.name"
               :status="selectedCoffeeMachine?.status"
               :date="selectedCoffeeMachine?.date_last_connection"
               :address="selectedCoffeeMachine?.address"
    >

    </InfoModal>
    <div class=" align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">Dashboard</h4>
        </div>
      </div>

    </div>
    <div class="card">
      <div class="card-body row">
        <div class="col-lg-5 d-flex flex-column justify-content-center align-items-center">
          <div class="w-100">
            <h4 class="card-title mb-4 text-start">Select range</h4>
            <div class="mb-3 mb-lg-4">
              <div style="width: 230px">
                <date-picker
                    class="w-100"
                    v-model="dateRange"
                    format="DD/MM/YYYY"
                    range
                    lang="en"
                    placeholder="Select date"
                ></date-picker>
              </div>
            </div>
          </div>
          <div class="col-8 col-sm-7 col-md-6 col-lg-8 card bg-primary m-0">
            <div class="card-body">
              <div class="text-center text-white py-4">
                <h5 class="mt-0  text-white-50 font-size-16">Top Sale</h5>
                <h1>1452</h1>
                <p class="font-size-14 pt-1 m-0">Coffee Machine 1</p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center col-lg-12 mt-3 mt-lg-4 w-100">
            <h4 class="card-title mb-4">Select coffee machine</h4>
            <div class="mb-3 mb-lg-4">
              <b-form-select style="width: 230px" class="h-100 form-control form-select"
                             v-model="coffeeMachineFilter" size="md" :options="coffeeMachines"/>
            </div>
            <v-chart class="overflow-hidden w-100" :options="pieChart" autoresize/>
          </div>
        </div>
        <div class="col-lg-7 e-charts">
          <gmap-map class="h-100" :center="{ lat: 50.25355 , lng: 28.66543 }" :zoom="6" >
            <gmap-marker
                v-for="(item, index) in tableData"
                :key="index"
                :position="item.position"
                :clickable="true"
                :draggable="false"
                @click="()=>clickHandler( item.id)"
            ></gmap-marker>
          </gmap-map>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.e-charts {
  min-height: 500px;
}
</style>